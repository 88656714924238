/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './styles/universal.css';

// start the Stimulus application
import './bootstrap';

const $ = require('jquery');
global.$ = global.jQuery = $;

global.bootstrap = require('bootstrap');

//specific for popover library
$(document).ready(function() {
    $('[data-toggle="popover"]').popover({
        sanitize: false,
        html: true,
    }).on('click', function (e) { //close any popover else on click this
        $('[data-toggle="popover"]').not(this).popover('hide');
    });

});

